import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import TopNavbar from '@/components/dashboard/TopNavbar';
import UserContext from '@/contexts/UserContext';
import LoadingScreen from '@/components/router/LoadingScreen';
import Footer from '@/components/Footer';
import Hero from '../components/landing/Hero';
import Wrapper from '../components/shared/Wrapper';

const Home = () => {
  const { t } = useTranslation();
  //  The current page is not under the management of the PrivateRoute component route, so user data can only be obtained manually.
  const { user, loading } = useContext(UserContext);
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('shared.appName')}</title>
        <link rel="canonical" href="https://cv.orientalent.se/" />
      </Helmet>

      {/* the TopNavbar component can only be rendered after the user data has been successfully retrieved. */}
      {user && <TopNavbar />}

      <div className="container px-8 xl:px-0 text-center md:text-left mt-24">
        <Hero />
        <p className="leading-loose text-3xl mt-16">
          With this Resume builder, you can easily create resumes, share them
          with anyone through an unique link or Export to a JSON file, without
          losing the integrity and privacy of your data.
        </p>
        <p className="leading-loose text-3xl mt-12">
          To start
          <br />
          <b>Login -&gt; Go To CV -&gt; Create Resume -&gt; Load Demo Data</b>
        </p>
        <p className="leading-loose text-2xl mt-10">
          <b>Note:</b> It is important to follow the same format of the Demo
          Data.
        </p>
      </div>
      <Footer />
    </Wrapper>
  );
};

export default memo(Home);
